export * from "shared/src/loan/LoanFunctions";

import { config, config_optional } from "shared/src/utils/ConfigValues";
import { LTVDangerLevels } from "shared/src/loan/LoanFunctions";
import { BigFloat } from "shared/src/utils/BigFloat";

type LTVTailwindClassesLeaf = {
  text:
    | "text-danger fill-danger"
    | "text-warning fill-warning"
    | "text-success fill-success";
  background: "bg-danger" | "bg-warning" | "bg-success";
};

type LTVTailwindClasses = {
  [key: string]: LTVTailwindClassesLeaf;
};

const LTVClasses = {
  LIQUIDATED: { text: "text-danger fill-danger", background: "bg-danger" },
  CRITICAL: { text: "text-danger fill-danger", background: "bg-danger" },
  POOR: { text: "text-warning fill-warning", background: "bg-warning" },
  FAIR: { text: "text-warning fill-warning", background: "bg-warning" },
  HEALTHY: { text: "text-success fill-success", background: "bg-success" },
} satisfies LTVTailwindClasses;

export function getLTVTextColor(dangerLevel: LTVDangerLevels) {
  return LTVClasses[dangerLevel].text;
}

export function getLTVBackgroundColor(dangerLevel: LTVDangerLevels) {
  return LTVClasses[dangerLevel].background;
}

export function getLoanCalculatorConfigValues() {
  const loanAmountCharLimit = parseInt(
    config("LOAN_CALC_LOAN_AMOUNT_CHAR_LIMIT", "7"),
    10
  );
  const loanAmountValueLimit = parseInt(
    config("LOAN_CALC_LOAN_AMOUNT_VALUE_LIMIT", "1000000"),
    10
  );
  const collateralCharLimit = parseInt(
    config("LOAN_CALC_COLLATERAL_CHAR_LIMIT", "17"),
    10
  );

  return {
    loanAmountCharLimit,
    loanAmountValueLimit,
    collateralCharLimit,
  };
}

// TODO: repurpose adapters
export function getInternalAdapterPackageName(name: string) {
  return name === "ETH-B" || name === "CBBTC-B"
    ? "High"
    : name === "ETH-A" || name === "CBBTC-A"
    ? "Mid"
    : "Low";
}

/**
 * Returns the max possible loan amount for test customers. If this is not set, no limit is applied.
 */
export function getMaxLoanForTesting() {
  const maxLoanAmount = config_optional("MAX_LOAN_AMOUNT");

  if (!maxLoanAmount) {
    return null;
  }

  if (isNaN(Number(maxLoanAmount))) {
    throw new Error("Max loan amount is set but is Nan: " + maxLoanAmount);
  }

  return BigFloat.fromString(maxLoanAmount);
}
