import { useFormField } from "src/forms/formHelpers";

import { ChangeEvent } from "react";
import { BigFloat } from "shared/src/utils/BigFloat";
import { getLoanAmountFromLtv, getLtvValues } from "src/loan/LoanFunctions";
import {
  SupportedCollateralId,
  SupportedLoanCurrencyId,
} from "shared/src/SupportedCurrencies";
import {
  formatFromDisplayString,
  formatToDisplayString,
  formatToDisplayStringWithCurrency,
} from "src/formatting/functions";

export function useCollateral(args: {
  collateralCurrency: SupportedCollateralId;
  startEmpty?: boolean;
}) {
  const { collateralCurrency, startEmpty } = args;

  // initial values arbitrarily selected to reflect roughly in the middle with a 40% ltv
  const initialCollateral: Record<SupportedCollateralId, BigFloat> = {
    CBBTC: BigFloat.fromNumber(1),
    ETH: BigFloat.fromNumber(5),
  };

  const [
    collateral,
    setCollateral,
    collateralError,
    validateCollateral,
    setCollateralError,
  ] = useFormField(
    startEmpty
      ? ""
      : formatToDisplayString(initialCollateral[collateralCurrency]),
    "text",
    true
  );

  const collateralBigFloat = BigFloat.fromString(
    formatFromDisplayString(collateral)
  );

  const maxCollateral = {
    ETH: BigFloat.fromNumber(1000),
    CBBTC: BigFloat.fromNumber(100),
  };

  const getMinCollateral = (args: {
    debtFloor: BigFloat;
    liquidationThreshold: BigFloat;
    adapterPrice: BigFloat;
  }) => {
    const { debtFloor, liquidationThreshold, adapterPrice } = args;

    return debtFloor.div(
      getLtvValues(liquidationThreshold).POOR.mul(adapterPrice)
    );
  };

  const collateralConversionText = (
    collateralToLoanRate: BigFloat,
    loanCurrency: SupportedLoanCurrencyId
  ) => {
    return collateral &&
      collateralBigFloat !== BigFloat.zero() &&
      collateralToLoanRate
      ? `≈  ${formatToDisplayStringWithCurrency(
          loanCurrency,
          collateralBigFloat.mul(collateralToLoanRate)
        )}`
      : "";
  };

  const onCollateralChange = (
    e: ChangeEvent<HTMLInputElement>,
    collateralToLoanRate: BigFloat,
    setLoanAmount: (value: string) => void
  ) => {
    // change loanAmount to reflect a 40% ltv when this is changed
    const formattedValue = formatFromDisplayString(e.target.value);

    if (
      e.target.value.length < e.target.maxLength &&
      parseFloat(formattedValue) < parseFloat(e.target.max)
    ) {
      const newLoanAmount = getLoanAmountFromLtv({
        ltv: BigFloat.fromNumber(0.4),
        collateral: BigFloat.fromString(formattedValue),
        price: collateralToLoanRate,
      });
      setLoanAmount(formatToDisplayString(newLoanAmount, false));
    }

    setCollateral(formatToDisplayString(e.target.value, false));
  };

  const catchCollateralErrors = (
    minCollateral: BigFloat,
    availableCollateralWalletBalance?: BigFloat
  ) => {
    let collateralError = validateCollateral();

    if (collateralBigFloat.lessThan(minCollateral)) {
      collateralError =
        "Min collateral: " +
        formatToDisplayStringWithCurrency(collateralCurrency, minCollateral);
    }

    if (collateralBigFloat.greaterThan(maxCollateral[collateralCurrency])) {
      collateralError = `Max collateral:  ${formatToDisplayStringWithCurrency(
        collateralCurrency,
        maxCollateral[collateralCurrency]
      )}`;
    }

    if (
      availableCollateralWalletBalance &&
      availableCollateralWalletBalance.lessThan(collateralBigFloat)
    ) {
      collateralError = `Insufficient wallet balance: ${formatToDisplayStringWithCurrency(
        collateralCurrency,
        availableCollateralWalletBalance,
        true,
        5
      )}`;
    }

    setCollateralError(collateralError);

    return collateralError;
  };

  return {
    collateral,
    setCollateral,
    collateralError,
    collateralBigFloat,
    onCollateralChange,
    catchCollateralErrors,
    initialCollateral,
    getMinCollateral,
    maxCollateral,
    collateralConversionText,
  };
}
