import pino from "pino";

const failIfNotFound = !config_optional("DISABLE_STRICT_ENV");

const logger = pino({ name: "ConfigValues" });

export function config(name: string, localDefault?: string): string {
  const useLocalDefault = process.env.USE_LOCAL_DEFAULT === "true";

  const value = process.env[name];

  if (value) {
    return value;
  }

  if (!localDefault || !useLocalDefault) {
    logger.warn({ name }, "Environment variable not set");

    if (failIfNotFound)
      throw new Error(`Environment variable ${name} is not set`);

    return "NOT_SET";
  }

  return localDefault;
}

export function config_optional(
  name: string,
  localDefault?: string
): string | undefined {
  const useLocalDefault = process.env.USE_LOCAL_DEFAULT === "true";

  const value = process.env[name];

  if (value) {
    return value;
  }

  if (!localDefault || !useLocalDefault) {
    return undefined;
  }

  return localDefault;
}
