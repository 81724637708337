import { formatUnits, parseUnits } from "viem";
import { Transform } from "./Transform";

export type SerializedBigFloat = `BigFloat:${bigint}:${number}`;

export class BigFloat {
  readonly number: bigint;
  readonly decimals: number;

  constructor(number: bigint, decimals: number) {
    this.number = number;
    this.decimals = decimals;
  }

  static parse(number: `${number}`, decimals: number) {
    return new BigFloat(parseUnits(number, decimals), decimals);
  }

  static fromNumber(number: number): BigFloat {
    return BigFloat.fromString(number.toString());
  }

  static fromString(numberString: string): BigFloat {
    numberString = numberString.trim();

    let decimals = 0;
    if (numberString.includes(".")) {
      const decimalIndex = numberString.indexOf(".");
      decimals = numberString.length - decimalIndex - 1;
      numberString = numberString.replace(".", "");
    }

    return new BigFloat(BigInt(numberString), decimals);
  }

  format() {
    return formatUnits(this.number, this.decimals);
  }

  formatAndRound(decimals: number = 2) {
    return Math.round(this.toNumber() * 10 ** decimals) / 10 ** decimals;
  }

  toDecimals(decimals: number) {
    const delta = decimals - this.decimals;

    if (delta === 0) {
      return this;
    } else if (delta > 0) {
      const number = this.number * BigInt(10) ** BigInt(delta);
      return new BigFloat(number, decimals);
    } else {
      const number = this.number / BigInt(10) ** BigInt(-delta);
      return new BigFloat(number, decimals);
    }
  }

  mul(other: BigFloat) {
    return new BigFloat(
      this.number * other.number,
      this.decimals + other.decimals
    );
  }

  div(other: BigFloat) {
    const targetDecimals = Math.max(this.decimals, other.decimals);

    const thisFloat = this.toDecimals(
      targetDecimals + other.decimals + targetDecimals
    );

    return new BigFloat(
      thisFloat.number / other.number,
      thisFloat.decimals - other.decimals
    );
  }

  pow(other: number) {
    return new BigFloat(
      this.number ** BigInt(other),
      this.decimals * Number(other)
    );
  }

  add(other: BigFloat) {
    if (this.decimals === other.decimals) {
      return new BigFloat(this.number + other.number, this.decimals);
    } else if (this.decimals > other.decimals) {
      const number =
        this.number +
        other.number * BigInt(10) ** BigInt(this.decimals - other.decimals);
      return new BigFloat(number, this.decimals);
    } else {
      const number =
        this.number * BigInt(10) ** BigInt(other.decimals - this.decimals) +
        other.number;
      return new BigFloat(number, other.decimals);
    }
  }

  sub(other: BigFloat) {
    if (this.decimals === other.decimals) {
      return new BigFloat(this.number - other.number, this.decimals);
    } else if (this.decimals > other.decimals) {
      const number =
        this.number -
        other.number * BigInt(10) ** BigInt(this.decimals - other.decimals);
      return new BigFloat(number, this.decimals);
    } else {
      const number =
        this.number * BigInt(10) ** BigInt(other.decimals - this.decimals) -
        other.number;
      return new BigFloat(number, other.decimals);
    }
  }

  toNumber() {
    return Number(this.format());
  }

  serialize() {
    return `BigFloat:${this.number.toString()}:${
      this.decimals
    }` as SerializedBigFloat;
  }

  compareTo(other: BigFloat): -1 | 0 | 1 {
    const targetDecimals = Math.max(this.decimals, other.decimals);
    const d1 = this.toDecimals(targetDecimals).number;
    const d2 = other.toDecimals(targetDecimals).number;
    if (d1 > d2) return 1;
    if (d1 === d2) return 0;
    // Just to make typescript happy, as this will always be true in this case.
    // if (d1 < d2)
    return -1;
  }

  equals(other: BigFloat) {
    return this.compareTo(other) === 0;
  }

  greaterThan(other: BigFloat) {
    return this.compareTo(other) === 1;
  }

  lessThan(other: BigFloat) {
    return this.compareTo(other) === -1;
  }

  greaterThanOrEqualTo(other: BigFloat) {
    return this.compareTo(other) >= 0;
  }

  lessThanOrEqualTo(other: BigFloat) {
    return this.compareTo(other) <= 0;
  }
  
  static deepSerialize<T>(
    value: T
  ): Transform<BigFloat, SerializedBigFloat, T> {
    if (value === null) {
      return null as Transform<BigFloat, SerializedBigFloat, T>;
    }

    if (value instanceof BigFloat) {
      return value.serialize() as Transform<BigFloat, SerializedBigFloat, T>;
    }

    if (typeof value === "object") {
      if (Array.isArray(value)) {
        return value.map((v) => BigFloat.deepSerialize(v)) as Transform<
          BigFloat,
          SerializedBigFloat,
          T
        >;
      }

      return Object.fromEntries(
        Object.entries(value).map(([key, value]) => [
          key,
          BigFloat.deepSerialize(value),
        ])
      ) as Transform<BigFloat, SerializedBigFloat, T>;
    }

    return value as Transform<BigFloat, SerializedBigFloat, T>;
  }

  static deepDeserialize<T>(
    value: T
  ): Transform<SerializedBigFloat, BigFloat, T> {
    if (value === null) {
      return null as Transform<SerializedBigFloat, BigFloat, T>;
    }

    if (value instanceof Date) {
      return value as Transform<SerializedBigFloat, BigFloat, T>;
    }

    if (typeof value === "object") {
      if (Array.isArray(value)) {
        return value.map((v) => BigFloat.deepDeserialize(v)) as Transform<
          SerializedBigFloat,
          BigFloat,
          T
        >;
      }

      return Object.fromEntries(
        Object.entries(value).map(([key, value]) => [
          key,
          BigFloat.deepDeserialize(value),
        ])
      ) as Transform<SerializedBigFloat, BigFloat, T>;
    }

    if (BigFloat.isSerializedBigFloat(value)) {
      return BigFloat.deserialize(value) as Transform<
        SerializedBigFloat,
        BigFloat,
        T
      >;
    }

    return value as Transform<SerializedBigFloat, BigFloat, T>;
  }

  static isSerializedBigFloat(
    serialized: unknown
  ): serialized is SerializedBigFloat {
    if (typeof serialized !== "string") {
      return false;
    }
    return serialized.match(/^BigFloat:[0-9]+:[0-9]+$/) !== null;
  }

  static zero() {
    return new BigFloat(BigInt(0), 0);
  }

  static deserialize(serialized: SerializedBigFloat) {
    const [bigFloatCheck, number, decimals] = serialized.split(":");

    if (bigFloatCheck !== "BigFloat") {
      throw new Error("Invalid serialized BigFloat");
    }

    const val = new BigFloat(BigInt(number), Number(decimals));
    return val;
  }
}
