import * as zod from "zod";
import { BigFloat, SerializedBigFloat } from "../utils/BigFloat";
import { LoanStatus, Transaction } from "@prisma/client";
import { Adapter } from "../services";
import {
  SupportedCollateralId,
  SupportedLoanCurrencyId,
} from "../SupportedCurrencies";

export type { Transaction };
export { LoanStatus };

export interface Loan {
  id: string;
  adapter: Adapter;
  collateral: BigFloat;
  loanAmount: BigFloat;
  status: LoanStatus;
  activeTransaction?: Transaction;
  userId: string;
  active: boolean;
  startDate: Date;
  collateralCurrency: SupportedCollateralId;
  loanCurrency: SupportedLoanCurrencyId;
}

export const newLoanRequestSchema = zod.object({
  userId: zod.string(),
  adapterId: zod.string(),
  collateral: zod.string(),
  loanAmount: zod.string(),
  exchangeRate: zod.string(),
  collateralCurrency: zod.custom<SupportedCollateralId>(),
  loanCurrency: zod.custom<SupportedLoanCurrencyId>(),
});

export type NewLoanRequest = zod.infer<typeof newLoanRequestSchema>;

export const newLoanResponseSchema = zod.object({
  loanId: zod.string(),
});

export type NewLoanResponse = zod.infer<typeof newLoanResponseSchema>;

export const closeLoanRequestSchema = zod.object({
  loanId: zod.string(),
  userId: zod.string(),
});

export type CloseLoanRequest = zod.infer<typeof closeLoanRequestSchema>;

export const closeLoanResponseSchema = zod.object({
  loanId: zod.string(),
});

export type CloseLoanResponse = zod.infer<typeof closeLoanResponseSchema>;

export const addCollateralRequestSchema = zod.object({
  loanId: zod.string(),
  userId: zod.string(),
  collateral: zod.custom<SerializedBigFloat>(BigFloat.isSerializedBigFloat),
});

export type AddCollateralRequest = zod.infer<typeof addCollateralRequestSchema>;

export const removeCollateralRequestSchema = zod.object({
  loanId: zod.string(),
  userId: zod.string(),
  collateral: zod.custom<SerializedBigFloat>(BigFloat.isSerializedBigFloat),
});

export type RemoveCollateralRequest = zod.infer<
  typeof removeCollateralRequestSchema
>;

export const addDebtRequestSchema = zod.object({
  loanId: zod.string(),
  userId: zod.string(),
  debt: zod.custom<SerializedBigFloat>(BigFloat.isSerializedBigFloat),
});

export type AddDebtRequest = zod.infer<typeof addDebtRequestSchema>;

export const removeDebtRequestSchema = zod.object({
  loanId: zod.string(),
  userId: zod.string(),
  debt: zod.custom<SerializedBigFloat>(BigFloat.isSerializedBigFloat),
});

export type RemoveDebtRequest = zod.infer<typeof removeDebtRequestSchema>;
