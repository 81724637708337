"server only";

import { BigFloat } from "../utils/BigFloat";
import { Loan, LoanStatus } from "./Model";

export interface LoanDetails {
  loanId: string;
  loanName: string;
  interestRate: BigFloat;
  collateral: BigFloat;
  collateralName: string;
  ltv: BigFloat;
  ltvLimit?: BigFloat;
  totalDebt: BigFloat;
  state: LoanViewLoanState;
  availableExtraLoanAmount: BigFloat;
}

export type LoanViewLoanState =
  | "PENDING"
  | "HEALTHY"
  | "FAIR"
  | "POOR"
  | "CRITICAL"
  | "LIQUIDATED"
  | "PAID"
  | "FAILED";

export function getYearlyInterestRate(rate: BigFloat) {
  return rate.mul(new BigFloat(BigInt(100), 0));
}

export function getYearlyInterestRateWithVanirFee(rate: BigFloat) {
  const vanirFee = BigFloat.fromNumber(2.5);
  return getYearlyInterestRate(rate).add(vanirFee);
}

export function getLoanDetails(loan: Loan): LoanDetails {
  const interestRate = getYearlyInterestRate(loan.adapter.annualRate);

  const loanName = loan.adapter.name;

  const collateral = loan.collateral;
  const collateralName = loan.adapter.assetId;

  const totalDebt = loan.loanAmount;

  const collateralValue = collateral.mul(loan.adapter.price);
  const ltv = totalDebt.div(collateralValue);

  const ltvLimit = loan.adapter.liquidationTreshold;

  const availableExtraLoanAmount = getAvailableExtraLoanAmountForLoan(loan);

  const state = getLoanState(
    ltv,
    loan.status,
    loan.adapter.liquidationTreshold
  );

  return {
    loanId: loan.id,
    loanName,
    collateral,
    totalDebt,
    interestRate,
    ltv,
    ltvLimit,
    collateralName,
    availableExtraLoanAmount,
    state,
  };
}

export function getLtv(args: {
  debt: BigFloat;
  collateral: BigFloat;
  price: BigFloat;
}): BigFloat {
  const { debt, collateral, price } = args;
  const collateralValue = collateral.mul(price);
  const ltv = debt.div(collateralValue);
  return ltv;
}

export function getCollateralFromLtv(args: {
  ltv: BigFloat;
  loanAmount: BigFloat;
  price: BigFloat;
}) {
  const { ltv, loanAmount, price } = args;

  const collateralValue = loanAmount.div(ltv);
  const collateral = collateralValue.div(price);

  return collateral;
}

export function getLoanAmountFromLtv(args: {
  ltv: BigFloat;
  collateral: BigFloat;
  price: BigFloat;
}) {
  const { ltv, collateral, price } = args;

  const collateralValue = collateral.mul(price);

  const loanAmount = ltv.mul(collateralValue);

  return loanAmount;
}

export function getAvailableExtraLoanAmountForLoan(loan: Loan): BigFloat {
  const collateralValue = loan.collateral.mul(loan.adapter.price);
  const ltvLimit = getLtvValues(loan.adapter.liquidationTreshold).POOR;
  const maxLoanAmount = collateralValue.mul(ltvLimit);
  const availableExtraLoanAmount = maxLoanAmount.sub(loan.loanAmount);

  return availableExtraLoanAmount;
}

export type LTVDangerLevels =
  | "CRITICAL"
  | "POOR"
  | "FAIR"
  | "HEALTHY"
  | "LIQUIDATED";

export function getDangerLevelForLtv(
  ltv: BigFloat,
  liquidationThreshold: BigFloat
): LTVDangerLevels {
  const level = getLtvValues(liquidationThreshold);

  if (ltv.greaterThan(level.LIQUIDATED)) return "LIQUIDATED";

  if (ltv.greaterThan(level.CRITICAL)) {
    return "CRITICAL";
  }

  if (ltv.greaterThan(level.POOR)) {
    return "POOR";
  }

  if (ltv.greaterThan(level.FAIR)) {
    return "FAIR";
  }

  return "HEALTHY";
}

function getLoanState(
  ltv: BigFloat,
  status: LoanStatus,
  liquidationThreshold: BigFloat
): LoanViewLoanState {
  if (status === LoanStatus.OPENING) {
    return "PENDING";
  }

  if (status === LoanStatus.CLOSED) {
    return "PAID";
  }

  if (status === LoanStatus.FAILED) {
    return "FAILED";
  }

  return getDangerLevelForLtv(ltv, liquidationThreshold);
}

// ltv thresholds, percentages are currently hardcoded
export function getLtvValues(liquidationThreshold: BigFloat) {
  // internal liq ltv is 1% less than the adapter liq threshold, rounded up to the nearest half
  const liqLtv = liquidationThreshold
    .sub(BigFloat.fromNumber(0.01))
    .toDecimals(2);

  // other values are derived from the new liquidation threshold
  return {
    LIQUIDATED: liqLtv,
    CRITICAL: liqLtv.sub(BigFloat.fromNumber(0.05)),
    POOR: liqLtv.sub(BigFloat.fromNumber(0.15)),
    FAIR: liqLtv.sub(BigFloat.fromNumber(0.25)),
    HEALTHY: BigFloat.fromNumber(0),
  };
}
