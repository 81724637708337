import pino from "pino";
import { config_optional } from "./ConfigValues";

const logger = pino();

// gets base web url, nextauth url if deployed on web and web url if on backend
export function baseWebUrlConfig() {
  const nextUrl = config_optional("NEXTAUTH_URL", "https://k33-pre.mewb.dev");
  const webUrl = config_optional("WEB_URL");

  const baseUrl = nextUrl ?? webUrl;

  if (!baseUrl) {
    logger.error("Could not find valid base web url at baseWebUrlConfig");
    throw new Error("No base url at baseWebUrlConfig");
  }

  return baseUrl;
}
