"use client";

import { PropsWithChildren, useState } from "react";
import { Detail } from "./Detail";
import { getYearlyInterestRate } from "shared/src/loan/LoanFunctions";
import { BigFloat, SerializedBigFloat, Transform } from "shared/src/utils";
import { formatToDisplayString } from "src/formatting/functions";

export type LoanDetailsProps = {
  adapterRate: Transform<BigFloat, SerializedBigFloat, BigFloat>;
  loanAmount: string;
  principalLoanAmount?: string;
  setupFeeAmount?: string;
  loanValueInDai?: string;
  loanValueInUSD?: string;
  loanValueInNok?: string;
  collateral: string;
  collateralCurrency: string;
  collateralInLoanCurrency: string;
  collateralPrice: string;
  collateralLiqPrice: string;
  currentLtv: number | string;
  maxLtv: number | string;
  liqLtv: number | string;
  loanFee?: number | string;
  padding?: string;
  daiToUsdRate?: string;
};

export function LoanDetailsContainer(
  props: LoanDetailsProps & PropsWithChildren
) {
  const [detailsHidden, setDetailsHidden] = useState(true);

  return (
    <div id="details-container" className="mt-2 flex w-full flex-col">
      <button
        id="show-details-button"
        onClick={() => setDetailsHidden(!detailsHidden)}
        className="rounded-bottom flex w-full flex-row items-center justify-start border-t bg-white p-3 pl-4 text-sm text-text hover:bg-secondary-action-focus focus:bg-secondary-action-focus"
      >
        {detailsHidden ? "⯆ show" : "⯅ hide"} details
      </button>
      {!detailsHidden && <LoanDetails padding="px-3 pb-3" {...props} />}
    </div>
  );
}

export function LoanDetails(props: LoanDetailsProps) {
  const {
    adapterRate,
    loanAmount,
    setupFeeAmount,
    principalLoanAmount,
    loanValueInDai,
    loanValueInNok,
    loanValueInUSD,
    collateral,
    collateralInLoanCurrency,
    collateralLiqPrice,
    collateralPrice,
    currentLtv,
    maxLtv,
    liqLtv,
    loanFee,
    padding,
    daiToUsdRate,
  } = props;
  let { collateralCurrency } = props;

  if (collateralCurrency === "CBBTC") {
    collateralCurrency = "cbBTC";
  }
  const k33APY = BigFloat.fromNumber(2.5);
  const blockchainAPY = getYearlyInterestRate(
    BigFloat.deepDeserialize(adapterRate)
  );
  const interest = blockchainAPY.add(k33APY);

  return (
    <div id="inner-details-container" className={"flex flex-col " + padding}>
      <Detail
        name="Interest rate"
        value={formatToDisplayString(interest) + "%"}
      />
      <Detail
        name={<div className="ml-3 italic">Blockchain Interest Rate</div>}
        value={
          <p className="flex h-full flex-col justify-center">
            {formatToDisplayString(blockchainAPY) + "%"}
          </p>
        }
      />
      <Detail
        name={<div className="ml-3 italic">K33 Service Fee</div>}
        value={
          <p className="flex h-full flex-col justify-center">
            {formatToDisplayString(k33APY) + "%"}
          </p>
        }
      />

      <Detail name="K33 Setup fee" value={loanFee + "%"} />

      <Detail name="Loan amount" value={loanAmount} />
      {principalLoanAmount && (
        <Detail
          name={<div className="ml-3 italic">Principal loan amount</div>}
          value={
            <p className="flex h-full flex-col justify-center">
              {principalLoanAmount}
            </p>
          }
        />
      )}
      {setupFeeAmount && (
        <Detail
          name={<div className="ml-3 italic">Setup fee</div>}
          value={
            <p className="flex h-full flex-col justify-center">
              {setupFeeAmount}
            </p>
          }
        />
      )}
      {loanValueInDai && (
        <Detail
          name="Loan value in DAI"
          value={
            <p className="flex h-full flex-col justify-center">
              {loanValueInDai}
            </p>
          }
        />
      )}
      {loanValueInNok && (
        <Detail
          name="Loan value in NOK"
          value={
            <p className="flex h-full flex-col justify-center">
              {loanValueInNok}
            </p>
          }
        />
      )}
      {loanValueInUSD && (
        <Detail
          name="Loan value in USD"
          value={
            <p className="flex h-full flex-col justify-center">
              {loanValueInUSD}
            </p>
          }
        />
      )}

      <Detail
        name="Collateral"
        value={
          <div className="flex flex-row justify-end gap-1 text-end">
            {collateral} {collateralCurrency}
            <p className="text-end font-normal">
              {" "}
              ≈ {" " + collateralInLoanCurrency}
            </p>
          </div>
        }
      />
      <Detail name="Current LTV" value={currentLtv + "%"} />
      <Detail name="Max LTV" value={maxLtv + "%"} />
      <Detail name="Liquidation LTV" value={liqLtv + "%"} />
      {daiToUsdRate && <Detail name="DAI/USD rate" value={daiToUsdRate} />}
      <Detail name={collateralCurrency + " price"} value={collateralPrice} />
      <Detail
        name={collateralCurrency + " liquidation price"}
        value={collateralLiqPrice}
      />
    </div>
  );
}
